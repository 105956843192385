import './App.css';
import WeatherApp from './components/WeatherApp/WeatherApp.jsx'
import Navbar from './components/WeatherApp/Navbar.jsx'


function App() {
  return (
    <div className="App">
      <Navbar />
      <WeatherApp />
    </div>
  );
}

export default App;
